import {CellButton, Group, Header, InfoRow, PanelHeader, PanelHeaderBack, Separator, SimpleCell, Skeleton, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {useEffect} from "react";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {Icon28GifOutline, Icon28Services} from "@vkontakte/icons";
import VkGroupMemberService from "../../../services/vk/VkGroupMemberService";
import {CustomPanel} from "../../../components/vkUI/CustomPanel";

export const VkGroupMemberShowPanel = ({...props}) => {
    const vkGroupMemberService = new VkGroupMemberService();
    const [fetching, setFetching] = React.useState(true);
    const params = useParams();
    const routeNavigator = useRouteNavigator();
    const [user, setUser] = React.useState({});

    const fetchData = async () => {
        await vkGroupMemberService.show(params.uuid).then(res => {
            setUser(vkGroupMemberService.model)
            setFetching(false);
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <CustomPanel id={props.id}>
            <PanelHeader
                delimiter="spacing"
                before={<PanelHeaderBack onClick={() => routeNavigator.back()} />}
            >
                {!fetching ? user.first_name + ' ' + user.last_name : <Skeleton width="100%" />}
            </PanelHeader>
            {
                !fetching ? <Group header={<Header mode="secondary">Информация о пользователе</Header>}>
                    <SimpleCell>
                        <InfoRow header="Имя">{user.first_name + ' ' + user.last_name}</InfoRow>
                    </SimpleCell>
                    <SimpleCell multiline>
                        <InfoRow header="Дата рождения">{user.birth_date ?? 'Не указана'}</InfoRow>
                    </SimpleCell>
                    <SimpleCell>
                        <InfoRow header="Город">{user.city ?? 'Не указан'}</InfoRow>
                    </SimpleCell>
                    <SimpleCell>
                        <InfoRow header="Телефон">{user.phone ?? 'Не указан'}</InfoRow>
                    </SimpleCell>
                    <SimpleCell>
                        <InfoRow header="VK ID">{user.vk_id}</InfoRow>
                    </SimpleCell>
                    {
                        user.screen_name && <SimpleCell>
                            <InfoRow header="Ссылка на профиль Вконтакте">
                                <a href={'https://vk.com/' + user.screen_name} target={'_blank'}>
                                    https://vk.com/{user.screen_name}
                                </a>
                            </InfoRow>
                        </SimpleCell>
                    }
                    <Separator />
                    <CellButton onClick={() => props.routeNavigator.push('/admin/groups/' + params.groupId + '/members/show/' + params.uuid + '/actions')} before={<Icon28Services />}>Действия подписчика</CellButton>
                    <CellButton onClick={() => props.routeNavigator.push('/admin/groups/' + params.groupId + '/members/show/' + params.uuid + '/gifts')} before={<Icon28GifOutline />}>Полученные подарки</CellButton>
                </Group> : <Group><Spinner size={'medium'} /></Group>
            }
        </CustomPanel>
    )
}