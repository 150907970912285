import {Avatar, Button, ButtonGroup, Placeholder, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {Icon12Delete, Icon12Pencil, Icon56UsersOutline} from "@vkontakte/icons";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {GroupContentList} from "../vkUI/GroupContentList";
import {GroupContent} from "../vkUI/GroupContent";
import {useEffect} from "react";
import store from "../../store";
import {useSelector} from "react-redux";
import GiftService from "../../services/gift/GiftService";
import {deleteAdminGiftInStore, setAdminGiftsInStore} from "../../features/gifts/adminGiftsSlice";

export const AdminGiftsList = ({limit, groupId, showAllLink, addLink}) => {
    const giftService = new GiftService();

    const [fetching, setFetching] = React.useState(true);
    const gifts = useSelector(state => state.adminGifts.list);
    const routeNavigator = useRouteNavigator();

    const fetchPanelData = async () => {
        await giftService.all({limit: limit, vk_group_id: groupId}).then(res => {
            store.dispatch(setAdminGiftsInStore(giftService.list));
            setFetching(false);
        });
    }

    const onClickDeleteGift = async (gift) => {
        await giftService.delete(gift.uuid).then(res => {
            store.dispatch(deleteAdminGiftInStore(gift.id))
        })
    }

    useEffect(() => {
        fetchPanelData()
    }, []);

    return (
        <GroupContent header={'Подарки'} showAllLink={showAllLink ? '/admin/groups/' + groupId + '/gifts' : null} addLink={addLink ? '/admin/groups/' + groupId + '/gifts/create' : null}>
            {
                !fetching ?
                    gifts.length ?
                        gifts.map((gift, index) => (
                            <GroupContentList
                                key={gift.id}
                                before={<Avatar size={48} src={gift.vkGroup ? gift.vkGroup.photo : null}/>}
                                content={gift.name}
                                text={gift.vkGroup ? gift.vkGroup.name : null}
                                after={
                                    <ButtonGroup>
                                        <Button before={<Icon12Pencil/>} onClick={() => routeNavigator.push('/admin/groups/' + groupId + '/gifts/update/' + gift.uuid)} mode="primary"/>
                                        <Button before={<Icon12Delete/>} onClick={() => onClickDeleteGift(gift)} mode={'secondary'}/>
                                    </ButtonGroup>
                                }/>
                        )) : <Placeholder icon={<Icon56UsersOutline/>}>
                            Пока еще нет подарков
                        </Placeholder> : <Spinner size={'medium'}/>
            }
        </GroupContent>
    )
}