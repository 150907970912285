import {CustomModal} from "../../components/vkUI/CustomModal";
import * as React from "react";
import {Group} from "@vkontakte/vkui";
import {useParams} from "@vkontakte/vk-mini-apps-router";
import {useEffect} from "react";
import ActionService from "../../services/action/ActionService";
import {VkSubscribeActionForm} from "../../components/vk/VkSubscribeActionForm";
import {VkSendReviewActionForm} from "../../components/vk/VkSendReviewActionForm";
import {VkSetLikeOnLastPostsActionForm} from "../../components/vk/VkSetLikeOnLastPostsActionForm";
import {VkSetLikeActionForm} from "../../components/vk/VkSetLikeActionForm";
import {VkSendCommentActionForm} from "../../components/vk/VkSendCommentActionForm";
import {VkRepostActionForm} from "../../components/vk/VkRepostActionForm";
import {VkActionDataContent} from "../../components/vk/VkActionDataContent";
import {VkGroupCard} from "../../components/vk/VkGroupCard";

export const VkActionModal = ({id, setPopout}) => {
    const actionService = new ActionService(setPopout)
    const params = useParams();
    const [headerText, setHeaderText] = React.useState('');
    const [action, setAction] = React.useState(null);

    const fetchData = async () => {
        await actionService.show(params.uuid).then(res => {
            setAction(actionService.model);
        });
    }

    const getActionForm = () => {
        if (action) {
            switch (action.action_type_id) {
                case 1:
                    return <VkSubscribeActionForm />
                case 2:
                    return <VkSendReviewActionForm />
                case 3:
                    return <VkSetLikeOnLastPostsActionForm />
                case 4:
                    return <VkSetLikeActionForm />
                case 5:
                    return <VkSendCommentActionForm />
                case 6:
                    return <VkRepostActionForm />
                default:
                    return null
            }
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <CustomModal id={id} headerText={action ? action.name : null}>
            {
                action && <Group>
                    <VkGroupCard vkGroup={action.vkGroup} />
                    <VkActionDataContent actionData={action.data} />
                    {getActionForm()}
                </Group>
            }
        </CustomModal>
    )
}