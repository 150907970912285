import {Avatar, Button, ButtonGroup, RichCell, UsersStack} from "@vkontakte/vkui";
import {Icon12View} from "@vkontakte/icons";
import {numToWord} from "../../helpers/StrHelper";
import * as React from "react";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";

export const AdminVkGroupItem = ({item, index}) => {
    const routeNavigator = useRouteNavigator();

    return (
        <RichCell
            key={index}
            onClick={() => routeNavigator.push('/admin/groups/show/' + item.group_id)}
            before={item.photo ? <Avatar size={40} src={item.photo} /> : null}
            caption={item.description}
            after={
                <ButtonGroup>
                    <Button before={<Icon12View />} onClick={() => routeNavigator.push('/admin/groups/show/' + item.group_id)} mode="primary" />
                </ButtonGroup>
            }
            bottom={
                item.membersPhotosInLbooster.length ?
                    <UsersStack photos={item.membersPhotosInLbooster}>
                        {item.membersPhotosInLbooster.length} {numToWord(item.membersPhotosInLbooster.length , ['подписчик в Lbooster', 'подписчика в Lbooster', 'подписчиков в Lbooster'])}
                    </UsersStack>
                    : null
            }
        >
            {item.name}
        </RichCell>
    )
}