import * as React from "react";
import {useEffect, useState} from "react";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {Avatar, Button, ButtonGroup, Div, Placeholder, RichCell, Spinner, UsersStack} from "@vkontakte/vkui";
import {Icon12View, Icon56SearchOutline, Icon56UsersOutline} from "@vkontakte/icons";
import {numToWord} from "../helpers/StrHelper";
import {CustomPagination} from "./CustomPagination";

export const List = ({service, renderItem}) => {
    const [fetching, setFetching] = React.useState(true);
    const [items, setItems] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationMeta, setPaginationMeta] = useState([]);

    const fetchListData = async () => {
        await service.allWithPaginate({page: currentPage}).then(res => {
            setItems(service.list);
            setPaginationMeta(service.paginateMeta);
            setFetching(false);
        });
    }

    const onPageChange = (page) => {
        setCurrentPage(page);
    }

    const onFilter = async () => {
        await fetchListData();
    }

    useEffect(() => {
        fetchListData()
    }, [currentPage]);

    return (
        !fetching ?
            items.length ?
                <Div>
                    {items.map((item, index) => (
                        <div key={index}>
                            {renderItem(item)}
                        </div>
                    ))}
                    <CustomPagination currentPage={currentPage} paginationMeta={paginationMeta} onPageChange={onPageChange}/>
                </Div>
                : <Placeholder icon={<Icon56SearchOutline />}>
                    Ничего не найдено
                </Placeholder> : <Spinner size={'medium'}/>
    )
}