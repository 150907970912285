import { configureStore } from '@reduxjs/toolkit'
import formErrorsSlice from './features/errors/formErrorsSlice'
import adminActionsSlice from "./features/actions/adminActionsSlice";
import adminGiftsSlice from "./features/gifts/adminGiftsSlice";
import adminBoostersSlice from "./features/boosters/adminBoostersSlice";
import appErrorsSlice from "./features/errors/appErrorsSlice";
import adminLoyaltyLevelsSlice from "./features/loyaltyLevels/adminLoyaltyLevelsSlice";
import appMessagesSlice from "./features/errors/appMessagesSlice";

export default configureStore({
    reducer: {
        appErrors: appErrorsSlice,
        appMessage: appMessagesSlice,
        formErrors: formErrorsSlice,
        adminActions: adminActionsSlice,
        adminLoyaltyLevels: adminLoyaltyLevelsSlice,
        adminGifts: adminGiftsSlice,
        adminBoosters: adminBoostersSlice,
    },
})