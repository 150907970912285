import {Avatar, CellButton, Flex, Group, Header, InfoRow, PanelHeader, PanelHeaderBack, Placeholder, RichCell, Separator, SimpleCell, Skeleton, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {useEffect} from "react";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import VkGroupMemberService from "../../../services/vk/VkGroupMemberService";
import {CustomPanel} from "../../../components/vkUI/CustomPanel";
import {Icon28CommentCircleFillBlue, Icon28LikeFillRed, Icon28MessageOutline, Icon28RepostCircleFillGreen} from "@vkontakte/icons";
import {CustomSpinner} from "../../../components/vkUI/CustomSpinner";

export const VkGroupMembersTopListPanel = ({...props}) => {
    const vkGroupMemberService = new VkGroupMemberService();
    const [fetching, setFetching] = React.useState(true);
    const params = useParams();
    const routeNavigator = useRouteNavigator();
    const [membersTopList, setMembersTopList] = React.useState({});

    const fetchData = async () => {
        await vkGroupMemberService.showTopList({vk_group_id: params.groupId}).then(res => {
            setMembersTopList(res)
            setFetching(false);
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <CustomPanel id={props.id}>
            <PanelHeader
                delimiter="spacing"
                before={<PanelHeaderBack onClick={() => routeNavigator.back()} />}
            >
                Рейтинг подписчиков
            </PanelHeader>
            {
                !fetching ?
                    membersTopList.length ?
                        <Group>
                            {
                                membersTopList.map((member, index) => (
                                    <RichCell
                                        before={<Avatar size={72} src={member.photo} />}
                                        after={member.loyaltyLevel ? member.loyaltyLevel.title : 'Не указан'}
                                        caption={
                                            <Flex justify={'start'} align={'center'} gap={3}  style={{marginTop: 5}}>
                                                <span style={{display: 'flex', alignItems: 'center'}}><Icon28LikeFillRed style={{marginRight: 3}} /> {member.likes_count}</span>
                                                <span style={{display: 'flex', alignItems: 'center', margin: '0 10px'}}><Icon28CommentCircleFillBlue style={{marginRight: 3}}  /> {member.comments_count}</span>
                                                <span style={{display: 'flex', alignItems: 'center'}}><Icon28RepostCircleFillGreen style={{marginRight: 3}}  /> {member.reposts_count}</span>
                                            </Flex>
                                        }
                                    >
                                        {index + 1}. {' ' + member.first_name + ' ' + member.last_name}
                                    </RichCell>
                                ))
                            }
                        </Group> : <Placeholder header={'Рейтинг еще не сформировался'} />
                    : <CustomSpinner />
            }
        </CustomPanel>
    )
}