import {Avatar, Button, ButtonGroup, Div, FormItem, Group, IconButton, Link, Placeholder, RichCell, Spinner, UsersStack} from "@vkontakte/vkui";
import * as React from "react";
import {Icon12View, Icon24Search, Icon56UsersOutline} from "@vkontakte/icons";
import {GroupContentList} from "../vkUI/GroupContentList";
import {GroupContent} from "../vkUI/GroupContent";
import {useEffect, useState} from "react";
import VkAdminGroupService from "../../services/vk/VkAdminGroupService";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {FormField} from "../form/FormField";
import {CustomPagination} from "../CustomPagination";
import {numToWord} from "../../helpers/StrHelper";
import {List} from "../List";
import {AdminVkGroupItem} from "./AdminVkGroupItem";

export const AdminVkGroupsList = () => {
    const vkGroupService = new VkAdminGroupService();
    const [formData, setFormData] = React.useState({});
    const routeNavigator = useRouteNavigator();

    // const syncVkGroups = async () => {
    //     setFetching(true)
    //     await vkGroupService.syncGroups().then(res => {
    //         setFetching(false);
    //     })
    // }

    const handler = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    return (
        <GroupContent
            header={'Сообщества'}
            separator={false}
        >
            {/*<Group>*/}
            {/*    <FormItem style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingLeft: 0, paddingTop: 0}}>*/}
            {/*        <FormField*/}
            {/*            type="input"*/}
            {/*            name={'name'}*/}
            {/*            placeholder="Введите название сообщества"*/}
            {/*            value={formData.name}*/}
            {/*            setValue={handler}*/}
            {/*            after={*/}
            {/*                <IconButton style={{background: 'rgba(235, 242, 250, 0.99)', borderLeft: '1px solid rgba(0, 0, 0, 0.2)'}} hoverMode="opacity" label="Поиск" onClick={onFilter}>*/}
            {/*                    <Icon24Search />*/}
            {/*                </IconButton>*/}
            {/*            }*/}
            {/*        />*/}
            {/*    </FormItem>*/}
            {/*</Group>*/}
            <List
                service={vkGroupService}
                renderItem={(item, index) => (
                    <AdminVkGroupItem item={item} index={index} />
                )}
            />
        </GroupContent>
    )
}