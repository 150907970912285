import {Form} from "../form/Form";
import * as React from "react";
import VkService from "../../services/vk/VkService";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {FormField} from "../form/FormField";
import ActionService from "../../services/action/ActionService";
import store from "../../store";
import {addAppMessage} from "../../features/errors/appMessagesSlice";

export const VkSendCommentActionForm = ({setPopout}) => {
    const actionService = new ActionService(setPopout);

    const routeNavigator = useRouteNavigator();
    const queryParams = new URLSearchParams(window.location.search);

    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({});
    const params = useParams();
    const handleChange = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onSubmitHandler = async() => {
        try {
            await actionService.execute({
                content: formData.comment,
                action_uuid: params.uuid,
                booster_uuid: queryParams.has('booster_uuid') ? queryParams.get('booster_uuid') : null
            }).then(res => {
                store.dispatch(addAppMessage({message: 'Действие успешно выполнено', subtitle: 'Баллы начислятся в течении нескольких минут.'}));
                routeNavigator.hideModal()
                setLoading(false)
            })
        } catch (exception) {
            setLoading(false)
        }
    }

    return (
        <Form loading={loading} onSubmit={onSubmitHandler} submitButtonText={'Отправить'}>
            <FormField
                title={'Текст комментария'}
                type={'input'}
                value={formData.comment}
                setValue={handleChange}
                name={'comment'}
            />
        </Form>
    )
}