import {Accordion, Avatar, CardGrid, CellButton, Counter, Div, Footer, Gradient, Group, Header, PanelHeader, PanelHeaderBack, Placeholder, Separator, SimpleCell, SimpleGrid, Spinner, Tooltip} from "@vkontakte/vkui";
import {VkUser} from "../../components/VkUser";
import * as React from "react";
import {showClientGroup} from "../../services/vk/VkClientGroupService";
import {useEffect} from "react";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {Icon16StarCircleFillYellow, Icon28CommentCircleFillBlue, Icon28DiscountOutline, Icon28LikeCircleFillRed, Icon28ListBulletSquareOutline, Icon28PollSquareOutline, Icon28RepostCircleFillGreen, Icon28VotesTransferCircleFillTurquoise} from "@vkontakte/icons";
import {CustomCard} from "../../components/vkUI/CustomCard";
import {CustomPanel} from "../../components/vkUI/CustomPanel";
import {numToWord} from "../../helpers/StrHelper";
import {LoyaltyLevelNextLevelInfo} from "../../components/loyaltyLevels/LoyaltyLevelNextLevelInfo";

export const VkGroupShowPanel = ({...props}) => {

    const params = useParams();
    const routeNavigator = useRouteNavigator();

    const [group, setGroup] = React.useState(null);
    const [fetching, setFetching] = React.useState(true);

    const fetchPanelData = async () => {
        await showClientGroup(params.groupId).then((response) => {
            setGroup(response)
            setFetching(false)
        })
    }

    const onClickAction = (action) => {
        return routeNavigator.push( '/groups/' + params.groupId + '/action/' + action.uuid);
    }

    useEffect(() => {
        fetchPanelData();
    }, [])

    return (
        <CustomPanel id={props.id}>
            {
                !fetching ?
                    <>
                        <PanelHeader
                            delimiter="spacing"
                            before={<PanelHeaderBack onClick={() => props.routeNavigator.push('/groups')}/>}
                            after={<VkUser user={props.user} mode={'plain'}/>}
                        >
                            {group.name}
                        </PanelHeader>
                        <Group>
                            <Placeholder
                                style={{padding: 10}}
                                icon={
                                    <Avatar src={group.photo} size={96} />
                                }
                                header={
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        {group.name} <span style={{display: 'flex', alignItems: 'center', paddingLeft: 5}}><Icon16StarCircleFillYellow style={{paddingRight: 2}}/> {group.rating}</span>
                                    </div>
                                }
                            >
                                {group.description}
                            </Placeholder>
                            <Group mode="plain">
                                {
                                    group.vkUserLoyaltyLevel && <SimpleCell before={<Icon28DiscountOutline />} indicator={group.vkUserLoyaltyLevel.this.title}>
                                        Уровень лояльности
                                    </SimpleCell>
                                }
                                <SimpleCell before={<Icon28LikeCircleFillRed />} indicator={<Counter mode="primary">{group.likesCount}</Counter>}>
                                    Лайков
                                </SimpleCell>
                                <SimpleCell before={<Icon28CommentCircleFillBlue />} indicator={<Counter mode="primary">{group.commentsCount}</Counter>}>
                                    Комментариев
                                </SimpleCell>
                                <SimpleCell before={<Icon28RepostCircleFillGreen />} indicator={<Counter mode="primary">{group.repostsCount}</Counter>}>
                                    Репостов
                                </SimpleCell>
                                <SimpleCell before={<Icon28VotesTransferCircleFillTurquoise />} indicator={<Counter mode="primary">{group.scoresCount}</Counter>}>
                                    Баллов сообщества
                                </SimpleCell>
                                <LoyaltyLevelNextLevelInfo group={group} />
                            </Group>
                            <Separator />
                            <CellButton
                                onClick={() => routeNavigator.push( '/groups/' + group.group_id + '/reviews')}
                                before={<Icon28ListBulletSquareOutline />}
                                after={group.reviews.length ? <span style={{color: 'black'}}>{group.reviews.length + ' '} {numToWord(group.reviews.length, ['отзыв', 'отзыва', 'отзывов'])}</span> : <span style={{color: 'black'}}>Пока нет отзывов</span>}
                            >
                                Отзывы о сообществе в Lbooster
                            </CellButton>
                            <CellButton
                                onClick={() => routeNavigator.push( '/groups/' + group.group_id + '/members/top')}
                                before={<Icon28PollSquareOutline />}
                            >
                                Рейтинг подписчиков
                            </CellButton>
                        </Group>
                        {
                            group.boosters.length > 0 && <Group description={'За прохождение бустеров вы получаете подарки, такие как купоны на скидку или товары.'}>
                                <Header mode="primary" size="large">
                                    Бустеры
                                </Header>
                                <Div style={{margin: '10 0'}}>
                                    {
                                        group.boosters.map((booster, index) => (
                                            <CustomCard
                                                key={index}
                                                onClick={() => props.routeNavigator.push('/boosters/show/' + booster.uuid)}
                                                image={booster.banner}
                                                title={booster.name}
                                                checked={booster.is_completed}
                                            />
                                        ))
                                    }
                                </Div>
                            </Group>
                        }
                        {
                            group.actions.length > 0 && <Group description={'За выполнение разовых заданий вы получаете баллы сообщества, которые можно обменять в магазине сообщества.'}>
                                <Header mode="primary" size="large">
                                    Разовые задания
                                </Header>
                                <Div style={{margin: '10 0'}}>
                                    {
                                        group.actions.map((action, index) => (
                                            <CustomCard
                                                key={index}
                                                onClick={() => !action.is_completed ? onClickAction(action) : null}
                                                image={action.banner}
                                                friends={action.friends}
                                                title={action.name}
                                                checked={action.is_completed}
                                                scores_count={action.amount}
                                            />
                                        ))
                                    }
                                </Div>
                            </Group>
                        }
                        {
                            group.gifts.length > 0 && <Group>
                                <Header mode="primary" size="large">
                                    Магазин
                                </Header>
                                <Div style={{margin: '10 0'}}>
                                    {
                                        group.gifts.map((gift, index) => (
                                            <CustomCard
                                                key={index}
                                                onClick={() => props.routeNavigator.push('/groups/' + params.groupId + '/gifts/buy/' + gift.uuid)}
                                                image={gift.banner}
                                                title={gift.name}
                                                friends={gift.friends}
                                                description={gift.description}
                                                checked={gift.user_already_has_gift}
                                                scores_count={gift.amount}
                                            />
                                        ))
                                    }
                                </Div>
                            </Group>
                        }
                    </>
                    : <Spinner size={'medium'}/>
            }
        </CustomPanel>
    )
}