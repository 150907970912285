import {CustomPanel} from "../../../components/vkUI/CustomPanel";
import {Group, Header, InfoRow, Link, PanelHeader, PanelHeaderBack, Separator, SimpleCell, Skeleton} from "@vkontakte/vkui";
import * as React from "react";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import VkAdminGroupService from "../../../services/vk/VkAdminGroupService";
import {useEffect, useState} from "react";
import {Icon28BoxCircleFillBlue, Icon28Crown, Icon28ListCheckOutline, Icon28PrinterOutline, Icon28Settings, Icon28StatisticCircleFillBlue, Icon28Users} from "@vkontakte/icons";
import {AdminActionsList} from "../../../components/action/AdminActionsList";
import {AdminGiftsList} from "../../../components/gift/AdminGiftsList";
import {AdminBoostersList} from "../../../components/booster/AdminBoostersList";
import {AdminLoyaltyLevelsList} from "../../../components/loyaltyLevels/AdminLoyaltyLevelsList";

export const AdminVkGroupShowPanel = ({...props}) => {
    const routeNavigator = useRouteNavigator();
    const vkGroupService = new VkAdminGroupService(props.setPopout);
    const [loading, setLoading] = useState(true);

    const params = useParams();

    const [vkGroup, setVKGroup] = useState({});

    const fetchData = async () => {
        await vkGroupService.show(params.groupId).then(res => {
            setVKGroup(vkGroupService.model);
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <CustomPanel id={props.id}>
            <PanelHeader
                delimiter="spacing"
                before={<PanelHeaderBack onClick={() => routeNavigator.push('/admin')}/>}
            >
                {vkGroup.name}
            </PanelHeader>
            <Group header={<Header mode="secondary">Информация</Header>}>
                <SimpleCell>
                    <InfoRow header="Ссылка">
                        {
                            !loading ?
                                <Link href={vkGroup.link} target={'_blank'}>
                                    {vkGroup.link}
                                </Link> : <Skeleton width="100%" />
                        }
                    </InfoRow>
                </SimpleCell>
            </Group>
            <Group header={<Header mode="secondary">Меню</Header>}>
                <SimpleCell
                    onClick={() => routeNavigator.push('/admin/groups/' + params.groupId + '/gifts/code/create')}
                    expandable="auto"
                    before={<Icon28BoxCircleFillBlue />}
                >
                    Сгенерировать купон
                </SimpleCell>
                <SimpleCell
                    onClick={() => routeNavigator.push('/admin/groups/show/' + params.groupId + '/stat')}
                    expandable="auto"
                    before={<Icon28StatisticCircleFillBlue />}
                >
                    Аналитика
                </SimpleCell>
                <SimpleCell
                    onClick={() => routeNavigator.push('/admin/groups/'+ params.groupId + '/members')}
                    expandable="auto"
                    before={<Icon28Users />}
                >
                    Подписчики
                </SimpleCell>
                <SimpleCell
                    onClick={() => routeNavigator.push('/admin/groups/update/'+ params.groupId)}
                    expandable="auto"
                    before={<Icon28Settings />}
                >
                    Настройки
                </SimpleCell>
            </Group>
            <AdminActionsList
                groupId={params.groupId}
                limit={5}
                addLink={true}
                showAllLink={true}
            />
            <AdminGiftsList
                groupId={params.groupId}
                limit={5}
                addLink={true}
                showAllLink={true}
            />
            <AdminBoostersList
                groupId={params.groupId}
                limit={5}
                addLink={true}
                showAllLink={true}
            />
            <AdminLoyaltyLevelsList
                groupId={params.groupId}
                limit={5}
                addLink={true}
                showAllLink={true}
            />
        </CustomPanel>
    )
}