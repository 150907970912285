import {Group, Header, PanelHeader, PanelHeaderBack, Placeholder, RichCell, Skeleton, Spinner, Tabs, TabsItem} from "@vkontakte/vkui";
import * as React from "react";
import {useEffect} from "react";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import VkGroupMemberService from "../../../services/vk/VkGroupMemberService";
import {CustomPanel} from "../../../components/vkUI/CustomPanel";

export const VkGroupMemberShowActionsPanel = ({...props}) => {
    const vkGroupMemberService = new VkGroupMemberService();
    const [fetching, setFetching] = React.useState(true);
    const params = useParams();
    const routeNavigator = useRouteNavigator();

    const [memberActions, setMemberActions] = React.useState([]);
    const [selectedActionType, setSelectedActionType] = React.useState('likes');

    const fetchData = async () => {
        await vkGroupMemberService.showActions(params.uuid).then(res => {
            setMemberActions(res)
            setFetching(false);
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <CustomPanel id={props.id}>
            <PanelHeader
                delimiter="spacing"
                before={<PanelHeaderBack onClick={() => routeNavigator.back()} />}
            >
                Действия подписчика
            </PanelHeader>
            <Group>
                <Tabs>
                    <TabsItem
                        selected={selectedActionType === 'likes'}
                        onClick={() => {
                            setSelectedActionType('likes');
                        }}
                        id="tab-likes"
                        aria-controls="tab-content-likes"
                    >
                        Лайки
                    </TabsItem>
                    <TabsItem
                        selected={selectedActionType === 'comments'}
                        onClick={() => {
                            setSelectedActionType('comments');
                        }}
                        id="tab-comments"
                        aria-controls="tab-content-comments"
                    >
                        Комментарии
                    </TabsItem>
                    <TabsItem
                        selected={selectedActionType === 'reposts'}
                        onClick={() => {
                            setSelectedActionType('reposts');
                        }}
                        id="tab-likes"
                        aria-controls="tab-content-reposts"
                    >
                        Репосты
                    </TabsItem>
                </Tabs>
            </Group>
            {
                selectedActionType === 'likes' && !fetching ? <Group>
                    {
                        memberActions.likes.length ?  memberActions.likes.map((item, index) => (
                            <RichCell
                                key={index}
                                subhead={'Пост от ' + new Date(item.data.date * 1000).toLocaleDateString('ru-RU')}
                            >
                                {item.data.text}
                            </RichCell>
                        )) : <Placeholder size={'medium'} header={'Пока что тут ничего нет :c'} />
                    }
                </Group> : null
            }
            {
                selectedActionType === 'comments' && !fetching ? <Group>
                    {
                        memberActions.comments.length ? memberActions.comments.map((item, index) => (
                            <RichCell
                                key={index}
                                subhead={'Пост от ' + new Date(item.data.date * 1000).toLocaleDateString('ru-RU')}
                                text={'Комментарий: ' + item.comment_data.text}
                            >
                                {item.data.text}
                            </RichCell>
                        )) : <Placeholder size={'medium'} header={'Пока что тут ничего нет :c'} />
                    }
                </Group> : null
            }
            {
                selectedActionType === 'reposts' && !fetching ? <Group>
                    {
                        memberActions.reposts.length ? memberActions.reposts.map((item, index) => (
                            <RichCell
                                key={index}
                                subhead={'Пост от ' + new Date(item.data.date * 1000).toLocaleDateString('ru-RU')}
                            >
                                {item.data.text}
                            </RichCell>
                        )) : <Placeholder size={'medium'} header={'Пока что тут ничего нет :c'} />
                    }
                </Group> : null
            }
        </CustomPanel>
    )
}