import * as React from 'react';
import {createRoot} from 'react-dom/client';
import {AdaptivityProvider, ConfigProvider, AppRoot,} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import {App} from "./App";
import {RouterProvider} from '@vkontakte/vk-mini-apps-router';
import {createBrowserRouter} from "react-router-dom";
import store from './store'
import {Provider} from 'react-redux'

const AUTH_ROUTES = [
    {
        path: '/login',
        panel: 'auth',
        view: 'auth',
    },
];

const USER_ROUTES = [
    {
        path: '/profile',
        panel: 'profile',
        view: 'profile',
    },
    {
        path: '/user/update',
        modal: 'update_user_data_modal',
        panel: 'profile',
        view: 'profile',
    },
    {
        path: '/user/scores/:vk_user_uuid',
        modal: 'vk_user_scores_modal',
        panel: 'profile',
        view: 'profile',
    },
    {
        path: '/user/enter-gift-code',
        modal: 'enter_gift_code_modal',
        panel: 'profile',
        view: 'profile',
    },
];

const VK_GROUP_ROUTES = [
    {
        path: '/',
        panel: 'vkGroupsListPanel',
        view: 'groups',
    },
    {
        path: '/groups/:groupId',
        panel: 'vkGroupShowPanel',
        view: 'groups',
    },
    {
        path: '/groups/:groupId/members/top',
        panel: 'vkGroupMembersTopListPanel',
        view: 'groups',
    },
    {
        path: '/groups/:groupId/reviews',
        panel: 'vkGroupReviewShowPanel',
        view: 'groups',
    },
    {
        path: '/groups/:groupId/action/:uuid',
        modal: 'vk_action_modal',
        panel: 'vkGroupShowPanel',
        view: 'groups',
    },
    {
        path: '/groups/:groupId/gifts/buy/:id',
        modal: 'buy_gift_modal',
        panel: 'vkGroupShowPanel',
        view: 'groups',
    },
]

const BOOSTERS_ROUTES = [
    {
        path: '/boosters/show/:boosterUuid',
        panel: 'boosterShowPanel',
        view: 'booster',
    },
    {
        path: '/boosters/show/:boosterUuid/action/:uuid',
        modal: 'vk_action_modal',
        panel: 'boosterShowPanel',
        view: 'booster',
    },
    {
        path: '/boosters/show/:uuid/gifts',
        modal: 'booster_gifts_modal',
        panel: 'boosterShowPanel',
        view: 'booster',
    },
]

const GIFTS_ROUTES = [
    {
        path: '/gifts',
        panel: 'gift',
        view: 'gift',
    },
    {
        path: '/gift/code/show/:uuid',
        modal: 'gift_qr_code_modal',
        panel: 'gift',
        view: 'gift',
    },
]

const ADMIN_DEFAULT_ROUTES = [
    {
        path: '/admin',
        panel: 'admin',
        view: 'admin',
    },
    {
        path: '/admin/check-gift',
        panel: 'adminCheckGiftPanel',
        view: 'admin',
    },
    {
        path: '/admin/user/settings',
        modal: 'admin_user_settings_modal',
        panel: 'admin',
        view: 'admin',
    },
]

const ADMIN_VK_GROUP_ROUTES = [
    {
        path: '/admin/groups/show/:groupId/stat',
        panel: 'adminStatPanel',
        view: 'admin',
    },

    {
        path: '/admin/groups/show/:groupId/members/stat',
        panel: 'adminStatGroupPanel',
        view: 'admin',
    },
    {
        path: '/admin/groups/update/:groupId',
        modal: 'admin_vk_group_update_modal',
        panel: 'adminVkGroupShowPanel',
        view: 'admin',
    },
    {
        path: '/admin/groups/show/:groupId',
        panel: 'adminVkGroupShowPanel',
        view: 'admin',
    },
]

const ADMIN_VK_GROUP_MEMBERS_ROUTES = [
    {
        path: '/admin/groups/:groupId/members',
        panel: 'adminVkUsersPanel',
        view: 'admin',
    },
    {
        path: '/admin/groups/:groupId/members/show/:uuid',
        panel: 'adminVkGroupMemberShowPanel',
        view: 'admin',
    },
    {
        path: '/admin/groups/:groupId/members/show/:uuid/actions',
        panel: 'adminVkGroupMemberShowActionsPanel',
        view: 'admin',
    },
    {
        path: '/admin/groups/:groupId/members/show/:uuid/gifts',
        panel: 'adminVkGroupMemberShowGiftsPanel',
        view: 'admin',
    },
]

const ADMIN_ACTIONS_ROUTES = [
    {
        path: '/admin/groups/:groupId/actions',
        panel: 'adminActionsListPanel',
        view: 'admin',
    },
    {
        path: '/admin/groups/:groupId/actions/create',
        modal: 'action_create_modal',
        panel: 'adminVkGroupShowPanel',
        view: 'admin',
    },
    {
        path: '/admin/groups/:groupId/actions/update/:uuid',
        modal: 'action_update_modal',
        panel: 'adminVkGroupShowPanel',
        view: 'admin',
    },
]

const ADMIN_GIFTS_ROUTES = [
    {
        path: '/admin/groups/:groupId/gifts',
        panel: 'adminGiftsListPanel',
        view: 'admin',
    },
    {
        path: '/admin/groups/:groupId/gifts/create',
        modal: 'gift_create_modal',
        panel: 'adminVkGroupShowPanel',
        view: 'admin',
    },
    {
        path: '/admin/groups/:groupId/gifts/update/:uuid',
        modal: 'gift_update_modal',
        panel: 'adminVkGroupShowPanel',
        view: 'admin',
    },
    {
        path: '/admin/groups/:groupId/gifts/code/create',
        modal: 'giftCode_create_modal',
        panel: 'adminVkGroupShowPanel',
        view: 'admin',
    },
]

const ADMIN_BOOSTER_ROUTES = [
    {
        path: '/admin/groups/:groupId/boosters',
        panel: 'adminBoosterListPanel',
        view: 'admin',
    },
    {
        path: '/admin/groups/:groupId/boosters/create',
        modal: 'booster_create_modal',
        panel: 'adminVkGroupShowPanel',
        view: 'admin',
    },
    {
        path: '/admin/groups/:groupId/boosters/update/:uuid',
        modal: 'booster_update_modal',
        panel: 'adminVkGroupShowPanel',
        view: 'admin',
    },
]

const ADMIN_LOYALTY_LEVELS_ROUTES = [
    {
        path: '/admin/groups/:groupId/loyalty/levels',
        panel: 'adminLoyaltyLevelsListPanel',
        view: 'admin',
    },
    {
        path: '/admin/groups/:groupId/loyalty/levels/create',
        modal: 'loyalty_level_create_modal',
        panel: 'adminVkGroupShowPanel',
        view: 'admin',
    },
    {
        path: '/admin/groups/:groupId/loyalty/levels/update/:uuid',
        modal: 'loyalty_level_update_modal',
        panel: 'adminVkGroupShowPanel',
        view: 'admin',
    },
]

const router = createBrowserRouter(
    [
        ...AUTH_ROUTES,
        ...USER_ROUTES,
        ...VK_GROUP_ROUTES,
        ...BOOSTERS_ROUTES,
        ...GIFTS_ROUTES,
        ...ADMIN_DEFAULT_ROUTES,
        ...ADMIN_VK_GROUP_ROUTES,
        ...ADMIN_VK_GROUP_MEMBERS_ROUTES,
        ...ADMIN_ACTIONS_ROUTES,
        ...ADMIN_GIFTS_ROUTES,
        ...ADMIN_BOOSTER_ROUTES,
        ...ADMIN_LOYALTY_LEVELS_ROUTES
    ]
);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <ConfigProvider appearance={'light'}>
        <AdaptivityProvider>
            <AppRoot>
                <RouterProvider router={router} notFoundRedirectPath="/">
                    <Provider store={store}>
                        <App/>
                    </Provider>
                </RouterProvider>
            </AppRoot>
        </AdaptivityProvider>
    </ConfigProvider>
    ,
);