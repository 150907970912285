import {Avatar, Div, Group, Header, Panel, PanelHeader, Separator, SimpleCell, Skeleton} from "@vkontakte/vkui";
import * as React from "react";
import {VkUser} from "../../components/VkUser";
import {Icon28GiftCircleFillYellow, Icon28UserCircleFillBlue, Icon28WalletOutline} from "@vkontakte/icons";
import {AdminVkGroupsList} from "../../components/vk/AdminVkGroupsList";

export const AdminPanel = ({routeNavigator, user, subscription}) => {
    return (
        <Panel>
            <PanelHeader after={<VkUser user={user} mode={'plain'} />}>
                Панель управления
            </PanelHeader>
            <Group style={{padding: 5}}>
                <SimpleCell subtitle={user.city} before={<Avatar size={40} src={user.photo} />} >
                    {user.name}
                </SimpleCell>
                <Separator />
                {
                    subscription ?
                        <SimpleCell indicator={'Активна до ' + subscription.next_payment_date } before={<Icon28WalletOutline />}>
                            Платная подписка
                        </SimpleCell> : <Div><Skeleton width="100%" /></Div>
                }
            </Group>
            <Group header={<Header mode="secondary">Меню</Header>}>
                <SimpleCell
                    onClick={() => routeNavigator.push('/admin/check-gift')}
                    expandable="auto"
                    before={<Icon28GiftCircleFillYellow />}
                >
                    Проверить подарок
                </SimpleCell>
                <SimpleCell
                    onClick={() => routeNavigator.push('/admin/user/settings')}
                    expandable="auto"
                    before={<Icon28UserCircleFillBlue />}
                >
                    Настройки
                </SimpleCell>
            </Group>
            <Group mode={'plain'}>
                <AdminVkGroupsList />
            </Group>
        </Panel>
    )
}